import { MenuEntry } from '@pancakeswap-libs/uikit'

const config:(t:any)=> MenuEntry[] =(t:any)=> [
  {
    label: t(60,'Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t(61,'Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t(7,'Exchange'),
        href: 'https://dex.benswap.cash/',
      },
      {
        label: t(8,'Liquidity'),
        href: 'https://dex.benswap.cash/#/pool'
      },
      {
        label: t(1146,'Aggregator'),
        href: 'https://dex.benswap.cash/#/dexaggregator',
      },
      {
        label: t(1163,'Gridex'),
        href: 'https://dex.benswap.cash/#/GriDexLiquidityList'
      },
      {
        label: t(1164,'Aggregator Pro'),
        href: 'https://dex.benswap.cash/#/aggregatorpro'
      },
      {
        label: t(1476,'Limit'),
        href: 'https://dex.benswap.cash/#/limit'
      },
      {
        label: t(1485,'P2P'),
        href: 'https://dex.benswap.cash/#/P2P'
      }
    ],
  },
  {
    label: t(1000,'Yield'),
    icon: 'PoolIcon',
    items: [
      {
        label: t(43,'Farms'),
        href: '/farms',
      },
      {
        label: t(44,'Pools'),
        href: '/pools',
      }
    ],
  },
  {
    label: t(1001,'Town'),
    icon: 'TownIcon',
    items: [
      {
        label: t(1002,'Candy House'),
        href: "/candyhouse/explorer",
      },
      {
        label: t(1007,'Charities'),
        href: "/charities/explorer",
      },
      {
        label: t(3011,'Cemetery'),
        href: "/cemetery",
      },
      {
        label: t(3087,'Laboratory'),
        href: "/laboratory",
      }
    ],
  },
  {
    label: t(432,'Ranches'),
    icon: 'CattleIcon2',
    items: [
      {
        label: t(308,'Create'),
        href: "/ranch/create",
      },
      {
        label:t(309,'Explorer'),
        href: "/ranches",
      },
      {
        label:t(427,'My Ranches'),
        href: "/ranch/my",
      },
      {
        label:t(508,'Favorites'),
        href: "/ranch/favorites",
      },
    ],
  },
  {
    label: t(720,'Games'),
    icon: 'TicketIcon',
    items: [
      {
        label: t(134,'Lottery'),
        href: '/lottery',
      },
      {
        label: t(1110,'PokéBen'),
        href: "/pokeben",
      },
      {
        label: t(4000,'Blind Boxes'),
        href: "/BlindBoxes",
      },
      {
        label: t(4002,'Island Explorer'),
        href: "/islandGame",
      },
    ],
  },
  {
    label: t(331,'Tools'),
    icon: 'ToyIcon2',
    items: [
      {
        label: t(307,'BenLock'),
        href: "/benlock",
      },
      {
        label:t(424,'Token Factory'),
        href: "/sep20",
      },
      {
        label:t(496,'AMM LP Finder'),
        href: "/lpfinder",
      },
      {
        label:t(2023,'Airdrop'),
        href: "/airdrop",
      },
      {
        label:t(2026,'Bridge'),
        href: "/bridge",
      },
      {
        label:t(1298,'NFT Factory'),
        href: "/nftfactory",
      },
    ],
  },
  {
    label: t(3001,'NFT Market'),
    icon: 'NftIcon',
    items: [
      {
        label: t(1161,'Featured'),
        href: '/nft/featured',
      },
      {
        label: t(309,'Explorer'),
        href: '/nft/collection',
      },
      {
        label: t(1162,'My Auctions'),
        href: '/nft/myorders',
      },
      {
        label: t(508,'Favorites'),
        href: '/nft/favorites',
      }
    ],
  },
  {
    label: t(549,'IBOv2'),
    icon: 'IboIcon',
    items: [
      {
        label: t(46,'Launch'),
        href: "/ibov2/create",
      },
      {
        label:t(309,'Explorer'),
        href: "/ibov2/explorer",
      },
      {
        label:t(1008,'Spotlight'),
        href: "/ibov2/spotlight",
      },
      {
        label: t(48,'My Campaigns'),
        href: "/ibov2/my",
      },
      {
        label:t(508,'Favorites'),
        href: "/ibov2/favorites",
      },
      {
        label: t(1009,'Market (v1)'),
        href: "/ibo/active",
      },
    ],
  },
  {
    label: t(572,'Governance'),
    icon: 'GovernmentIcon1',
    items: [
      {
        label: t(1147,'Votes'),
        href: '/votes',
      },
      {
        label: t(573,'Discussions'),
        href: "https://gov.benswap.cash/",
      },
      {
        label: t(574,'Proposals'),
        href: "https://vote.benswap.cash/",
      },
    ],
  },
  {
    label: t(51,'Info'),
    icon: "InfoIcon",
    items: [
      {
        label: t(135,'Analytics (AMM)'),
        href: "https://info.benswap.cash",
      },
      {
        label: t(136,'Analytics (Gridex)'),
        href: "https://grid.benswap.cash",
      },
      {
        label: t(52,'ChartPro'),
        href: "https://chartpro.benswap.cash/",
      },
      {
        label: t(1224,"Market Cap"),
        href: "http://marketcap.benswap.cash/",
      },
      {
        label: "Smartscout",
        href: "https://smartscout.cash/",
      },
      {
        label: t(301,'API Endpoints'),
        href: `https://docs.benswap.cash/${t(1330,'')}features/api`,
      },
      {
        label: "vfat.tools",
        href: "https://vfat.tools/smartbch/benswap/",
      },
      {
        label: "DeBank",
        href: "https://debank.com/protocols/sbch_benswap",
      },
      {
        label: "Defi Llama",
        href: "https://defillama.com/protocol/benswap",
      },
    ],
  },
  {
    label: t(49,'Audits'),
    icon: 'AuditIcon',
    items: [
      {
        label: t(50,'CertiK'),
        href: "https://www.certik.org/projects/bentoken-finance",
      },
    ],
  },
  // {
  //   label: t(124,'Listings'),
  //   icon: 'ListingIcon',
  //   items: [
  //     {
  //       label: 'CoinMarketCap',
  //       href: "https://coinmarketcap.com/currencies/green-ben/",
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: "https://www.coingecko.com/en/coins/green-ben",
  //     },
  //     {
  //       label: t(1222,"Yahoo! Finance"),
  //       href: "https://finance.yahoo.com/quote/EBEN-USD",
  //     },
  //     {
  //       label: t(1437,"Crypto.com Price"),
  //       href: "https://crypto.com/price/green-ben",
  //     },
  //     {
  //       label: t(1438,"BitDegree"),
  //       href: "https://www.bitdegree.org/cryptocurrency-prices/green-ben-eben-price",
  //     },
  //     {
  //       label: t(1439,"Binance Price"),
  //       href: "https://www.binance.com/en/price/green-ben",
  //     },
  //     {
  //       label: t(1440,"Coinbase Price"),
  //       href: "https://www.coinbase.com/price/green-ben",
  //     },
  //   ],
  // },
  {
    label: t(53,'Ecosystem'),
    icon: 'BenIcon',
    items: [
      {
        label: t(54,'Ben Token (BSC)'),
        href: "https://bentoken.finance/",
      },
      {
        label: t(55,'BenSwap (BSC)'),
        href: 'https://benswap.finance/',
      },
    ],
  },
  {
    label: t(56,'More'),
    icon: 'MoreIcon',
    items: [
      {
        label:t(57,'Github'),
        href: "https://github.com/BenTokenFinance",
      },
      {
        label: t(58,'Docs'),
        href: `https://docs.benswap.cash/${t(1330,'')}`,
      },
      {
        label: t(59,'Amber Testnet'),
        href: 'https://amber.benswap.cash/'
      }
    ],
  },
]

export default config
